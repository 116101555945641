<template>
	<PageLayout hide-back-button medium-width>
		<div class="card">
			<div class="container">
				<Spacer height size="xl" />
				<img class="container-img" :src="require('../../assets/icons/success.png')" />
				<Spacer height size="xl" />
				<h1 class="container-header">{{ $t('wallets.wallet-created') }}</h1>
				<span class="container-label">{{ $t('wallets.wallet-created-successfully') }}</span>
			</div>
			<Spacer height size="xl" />
			<div class="buttons">
				<div class="buttons-content">
					<CButton primary full-width @click="navigateTo('/')">
						{{ $t('actions.back-to-dashboard') }}
					</CButton>
				</div>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import CButton from '@/shared/cbutton/CButton';
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'WalletCreated',
	components: {
		PageLayout,
		CButton,
		Spacer,
	},
	methods: {
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
	},
};
</script>
<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';
.card {
	@include card;
	flex-grow: 1;
	min-width: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	&-header {
		font-size: $heading;
		margin: 0px;
	}

	&-label {
		font-size: $label-md;
		color: $white;
		opacity: 0.5;
	}

	&-img {
		width: 56px;
	}
}
</style>
